// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import chat from '@src/views/apps/chat/store/reducer'
import todo from '@src/views/apps/todo/store/reducer'
import users from '@src/views/apps/user/store/reducer'
import email from '@src/views/apps/email/store/reducer'
import invoice from '@src/views/apps/invoice/store/reducer'
import ecommerce from '@src/views/apps/ecommerce/store/reducer'
import dataTables from '@src/views/tables/data-tables/store/reducer'

import partners from '@castifyViews/partners/store/reducer'
import brands from '@castifyViews/brands/store/reducer'
import statsBeaconsStats from '@castifyViews/stats/beaconsStats/store/reducer'
import errorsBeacons from '@castifyViews/errors/beacons/store/reducer'
import rokuChannel from '@castifyViews/rokuChannels/store/reducer'
import videoLibrary from '@castifyViews/videoLibrary/store/reducer'
import playlist from '@castifyViews/playlist/store/reducer'
import videoAnalyticsDashboard from '@castifyViews/videoAnalytics/dashboard/store/reducer'

const rootReducer = combineReducers({
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  ecommerce,
  dataTables,
  
  partners,
  brands,
  statsBeaconsStats,
  rokuChannel,
  errorsBeacons,
  videoLibrary,
  playlist,
  videoAnalyticsDashboard
})

export default rootReducer
